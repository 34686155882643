import { ActionIcon, Stack, Text, Textarea, useMantineTheme } from '@mantine/core';
import YesNoButtons from '../YesNoButtons/YesNoButtons';
import classes from './YesNoInput.module.scss';
import { X } from '@phosphor-icons/react';

interface YesNoInputProps {
  // true = yes, false = no, null = not answered
  value: boolean | null;
  onYes: () => void;
  onNo: () => void;
  inputValue: string | null;
  onInputChange: (value: string) => void;
  showInput: boolean | null;
  label?: string;
  placeholder?: string;
  trackYesAction?: () => void;
  trackNoAction?: () => void;
  trackInputAction?: () => void;
  trackInputCloseAction?: () => void;
  questionShortTitle?: string;
  attendeeFullName?: string;
}

const YesNoInput: React.FC<YesNoInputProps> = ({
  label,
  value,
  onYes,
  onNo,
  inputValue,
  onInputChange,
  showInput,
  trackYesAction,
  trackNoAction,
  trackInputAction,
  trackInputCloseAction,
  placeholder = 'Add information',
  questionShortTitle,
  attendeeFullName,
}) => {
  const theme = useMantineTheme();

  return (
    <Stack gap={0}>
      <Text>{label}</Text>
      {showInput ? (
        <div style={{ position: 'relative' }}>
          <Textarea
            classNames={{
              input: classes.textareaInput,
            }}
            placeholder={placeholder}
            style={{
              borderRadius: '20px',
            }}
            value={inputValue || undefined}
            onChange={(event) => onInputChange(event.currentTarget.value)}
            onBlur={trackInputAction}
            data-testid={`${questionShortTitle} ${
              attendeeFullName ? attendeeFullName : ''
            } answer input`}
          />
          <ActionIcon
            variant="filled"
            style={{
              position: 'absolute',
              top: -5,
              right: -5,
              backgroundColor: theme.colors.gray[6],
              padding: 2,
            }}
            size={16}
            title="Close text area"
            onClick={() => {
              onNo();

              trackInputCloseAction?.();
            }}
          >
            <X size={16} color="white" weight="bold" />
          </ActionIcon>
        </div>
      ) : (
        <YesNoButtons
          value={value}
          onYes={onYes}
          onNo={onNo}
          trackYesAction={trackYesAction}
          trackNoAction={trackNoAction}
          questionShortTitle={questionShortTitle}
          attendeeFullName={attendeeFullName}
        />
      )}
    </Stack>
  );
};

export default YesNoInput;
