import React from 'react';
import { Button, Group } from '@mantine/core';
import classNames from 'classnames';
import classes from './YesNoButtons.module.scss';

interface YesNoButtonsProps {
  // true = yes, false = no, null = not answered
  value: boolean | null;
  onYes: () => void;
  onNo: () => void;
  trackYesAction?: () => void;
  trackNoAction?: () => void;
  questionShortTitle?: string;
  attendeeFullName?: string;
}

const YesNoButtons: React.FC<YesNoButtonsProps> = ({
  value,
  onYes,
  onNo,
  trackYesAction,
  trackNoAction,
  questionShortTitle,
  attendeeFullName,
}: YesNoButtonsProps) => {
  return (
    <Group grow w="full">
      <Button
        data-testid={`${questionShortTitle} ${attendeeFullName ? attendeeFullName : ''} YES answer`}
        onClick={() => {
          trackYesAction?.();

          onYes();
        }}
        className={classNames(classes.button, {
          [classes.selectedButton]: value,
        })}
      >
        Yes
      </Button>
      <Button
        data-testid={`${questionShortTitle} ${attendeeFullName ? attendeeFullName : ''} NO answer`}
        onClick={() => {
          trackNoAction?.();

          onNo();
        }}
        className={classNames(classes.button, {
          [classes.selectedButton]: value === false,
        })}
      >
        No
      </Button>
    </Group>
  );
};

export default YesNoButtons;
