import { Stack, Text, useMantineTheme, Textarea } from '@mantine/core';
import { CustomQuestionItem } from 'interfaces';
import { CustomQuestionTypeEnum } from 'enums';
import YesNoButtons from '../YesNoButtons/YesNoButtons';
import YesNoInput from '../YesNoInput/YesNoInput';

interface CustomQuestionCardAttendeeAnswersProps {
  customQuestion: CustomQuestionItem;
  handleUpdateAttendeeAnswer: (questionId: string, attendeeId: string, value: string) => void;
  showInput: Record<string, boolean>;
  setShowInput: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
}

const CustomQuestionCardAttendeeAnswers: React.FC<CustomQuestionCardAttendeeAnswersProps> = ({
  customQuestion,
  handleUpdateAttendeeAnswer,
  showInput,
  setShowInput,
}) => {
  const theme = useMantineTheme();

  const { question } = customQuestion;

  if (customQuestion.question.isPerAttendee === false) {
    return null;
  }

  return (
    <Stack>
      {customQuestion.attendeeAnswers?.map(
        (attendeeAnswer, index) =>
          attendeeAnswer !== null && (
            <Stack key={`${attendeeAnswer.attendeeId}-${index}`} gap={0}>
              <Text mb={theme.spacing.sm}>{attendeeAnswer.fullName}</Text>

              {question.type === CustomQuestionTypeEnum.YES_NO && (
                <YesNoButtons
                  data-testid={`${customQuestion.question.shortTitle} ${attendeeAnswer.fullName} answer`}
                  value={
                    attendeeAnswer.answer === 'YES'
                      ? true
                      : attendeeAnswer.answer === 'NO'
                      ? false
                      : null
                  }
                  onYes={() =>
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, 'YES')
                  }
                  onNo={() =>
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, 'NO')
                  }
                  questionShortTitle={customQuestion.question.shortTitle}
                  attendeeFullName={attendeeAnswer.fullName}
                />
              )}

              {question.type === CustomQuestionTypeEnum.YES_NO_INPUT && (
                <YesNoInput
                  value={
                    attendeeAnswer.answer === 'YES'
                      ? true
                      : attendeeAnswer.answer === 'NO'
                      ? false
                      : null
                  }
                  onYes={() => {
                    setShowInput((prevState) => ({
                      ...prevState,
                      [attendeeAnswer.attendeeId]: true,
                    }));

                    // init input value to empty string
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, '');
                  }}
                  onNo={() => {
                    setShowInput((prevState) => ({
                      ...prevState,
                      [attendeeAnswer.attendeeId]: false,
                    }));

                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, 'NO');
                  }}
                  inputValue={attendeeAnswer.answer}
                  onInputChange={(value) =>
                    handleUpdateAttendeeAnswer(customQuestion.id, attendeeAnswer.attendeeId, value)
                  }
                  showInput={showInput[attendeeAnswer.attendeeId] || false}
                  questionShortTitle={customQuestion.question.shortTitle}
                  attendeeFullName={attendeeAnswer.fullName}
                />
              )}

              {question.type === CustomQuestionTypeEnum.TEXT_INPUT && (
                <Textarea
                  autosize
                  minRows={1}
                  data-testid={`${customQuestion.question.shortTitle} ${attendeeAnswer.fullName} answer input`}
                  value={attendeeAnswer.answer || undefined}
                  onChange={(event) =>
                    handleUpdateAttendeeAnswer(
                      customQuestion.id,
                      attendeeAnswer.attendeeId,
                      event.currentTarget.value,
                    )
                  }
                />
              )}
            </Stack>
          ),
      )}
    </Stack>
  );
};

export default CustomQuestionCardAttendeeAnswers;
